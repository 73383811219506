/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {useTimeout} from './useTimeout';

export function useDebounce(func, time, deps) {
    const {reset, clear} = useTimeout(func, time);

    useEffect(() => {
        reset();
    }, [...deps, reset]);

    useEffect(() => {
        clear();
    }, []);
}
