/* eslint-disable @next/next/no-img-element */
/* eslint-disable max-len */
import classNames from 'classnames';
import React from 'react';

import styles from './barTeleQuebec.module.scss';

const BarTeleQuebec = () => {
    return (
        <div data-component="BarTeleQuebec" className={styles.me}>
            <div className={classNames('body', styles.banner)}>
                <a id="telequebec" href="https://www.telequebec.tv" target="_blank" rel="noreferrer">
                    <img
                        width="102"
                        height="20"
                        src="/images/logo/tele-quebec-horizontal.svg"
                        alt="Télé-Québec"
                    />
                    <svg aria-labelledby="telequebec" width="16" height="16" className={styles.linkIcon}>
                        <path
                            d="M0 0v16h16V0H0zm13 9.7l-2.3-2.3L7 11.1c-.1.1-1.5 1.4-.8 2.9-1.7-.1-2.5-.9-2.5-2.4 0-1 1-2.2 1.3-2.6l3.7-3.7L6.3 3H13v6.7z"
                        />
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default BarTeleQuebec;
