import React from "react";
import classNames from "classnames";

import { Gabarit, Image as ImageType } from "@/types/image";
import { TYPE_GABARIT } from "../../utils/enums";
import Image from "next/image";

import styles from "./image.module.scss";
import { CldImage } from "next-cloudinary";

export const placeHolders1X1 = {
    id: 999999,
    type: "placeholder",
    typeGabarit: TYPE_GABARIT.FORMAT_1X1,
    path: "/images/placeholders/logo/1x1",
    description: "null",
    declinaisons: [
        {
            file: "w100_h100.jpg",
            height: 100,
            upscale: false,
            width: 100,
        },
        {
            file: "w150_h150.jpg",
            height: 150,
            upscale: false,
            width: 150,
        },
        {
            file: "w226_h226.jpg",
            height: 226,
            upscale: false,
            width: 226,
        },
        {
            file: "w640_h640.jpg",
            height: 640,
            upscale: false,
            width: 640,
        },
    ],
};

export const placeHolders16X9 = {
    id: 999998,
    type: "placeholder",
    typeGabarit: TYPE_GABARIT.FORMAT_16X9,
    path: "/images/placeholders/logo/16x9",
    description: "",
    declinaisons: [
        {
            file: "w368_h207.jpg",
            height: 207,
            upscale: false,
            width: 368,
        },
        {
            file: "w1200_h675.jpg",
            height: 675,
            upscale: false,
            width: 1200,
        },
    ],
};

export const placeHolders2X3 = {
    id: 999997,
    type: "placeholder",
    typeGabarit: TYPE_GABARIT.FORMAT_2X3,
    path: "/images/placeholders/logo/2x3",
    description: "",
    declinaisons: [
        {
            file: "w200_h300.jpg",
            height: 300,
            upscale: false,
            width: 200,
        },
        {
            file: "w400_h600.jpg",
            height: 600,
            upscale: false,
            width: 400,
        },
        {
            file: "w800_h1200.jpg",
            height: 1200,
            upscale: false,
            width: 800,
        },
    ],
};

export const placeHoldersLOGO = {
    id: 999996,
    type: "placeholder",
    typeGabarit: TYPE_GABARIT.FORMAT_LOGO,
    path: "/images/placeholders/logo/16x9",
    description: "",
    declinaisons: [
        {
            file: "w368_h207.jpg",
            height: 207,
            upscale: false,
            width: 368,
        },
        {
            file: "w1200_h675.jpg",
            height: 675,
            upscale: false,
            width: 1200,
        },
    ],
};

export const placeHolders = [placeHolders1X1, placeHolders16X9, placeHolders2X3, placeHoldersLOGO];

type PropTypes = {
    alt?: string;
    ariaLabel?: string;
    className?: string;
    gabarits: Gabarit[];
    images?: ImageType[];
    lazyLoadImage?: boolean;
    width?: number;
};
const InImages = (current: Gabarit) => (img: ImageType) => img?.typeGabarit === current;

export const getSelectedImage = (images: ImageType[], gabarits: Gabarit[]) => {
    if (!gabarits || gabarits.length < 1) {
        return null;
    }

    const dataImages = images?.length > 0 ? images : [];
    // Cherche la première image qui match un gabarit, en ordre dans l'array de gabarit
    const firstGabaritFound = gabarits.findIndex((gabarit) => dataImages.find(InImages(gabarit)));
    const selectedImageData = dataImages.find(
        (img) => img.typeGabarit == gabarits[firstGabaritFound],
    );

    // Cherche la première image qui match un imageGhost, en ordre dans l'array de gabarit
    const firstGabaritGhostFound = gabarits.findIndex((gabarit) =>
        placeHolders.find(InImages(gabarit)),
    );
    const selectedImageGhost = placeHolders.find(
        (img) => img.typeGabarit == gabarits[firstGabaritGhostFound],
    );

    //  Si pas d'image trouvé dans aucun des gabarits, on fallback au placeholder
    return selectedImageData || selectedImageGhost;
};

export const getImageDatas = (images?: ImageType[], gabarits?: Gabarit[]) => {
    if (!images || !gabarits) {
        return null;
    }

    const selectedImage = getSelectedImage(images, gabarits);
    if (!selectedImage) {
        return null;
    }

    const { declinaisons, path, description, type } = selectedImage;

    if (!declinaisons || declinaisons.length < 1 || !path) {
        return null;
    }

    const declinaison = declinaisons.reduce((a, b) => (a?.width > b.width ? a : b));

    return {
        height: declinaison?.height,
        src: declinaison?.file && `${path}/${declinaison?.file}`,
        width: declinaison?.width,
        description,
        type,
    };
};

const ImageComponent = ({ alt, ariaLabel, className, gabarits, images }: PropTypes) => {
    if (!gabarits || !images) {
        return null;
    }

    const selectedImage = getSelectedImage(images, gabarits);
    if (!selectedImage?.declinaisons || !selectedImage?.path) {
        return null;
    }

    const {
        height: imageHeight,
        src,
        width: imageWidth,
        description,
        type,
    } = getImageDatas(images, gabarits) || {};

    const imageAlt = alt || description || "";

    return (
        <>
            {process.env.NODE_ENV === "development" || type == "placeholder" ? (
                <Image
                    src={src || ""}
                    className={classNames(
                        className,
                        styles.image,
                        styles[selectedImage.typeGabarit || ""],
                    )}
                    alt={imageAlt}
                    width={imageWidth}
                    height={imageHeight}
                    aria-label={ariaLabel || description}
                    loading="lazy"
                />
            ) : (
                <CldImage
                    deliveryType="fetch"
                    className={classNames(
                        className,
                        styles.image,
                        styles[selectedImage.typeGabarit || ""],
                    )}
                    src={src || ""}
                    alt={imageAlt}
                    width={imageWidth}
                    height={imageHeight}
                    aria-label={ariaLabel || description}
                    loading="lazy"
                    format="webp"
                />
            )}
        </>
    );
};

export default ImageComponent;
