import classNames from "classnames";
import React from "react";
import Bouton from "../bouton";
import styles from "./hamburgerMenuButton.module.scss";

type PropTypes = {
    handleToggleMenu?: (arg1: any, arg2?: any) => void;
    isOpened: boolean;
    ariaControls?: string;
};

const HamburgerMenuButton = (props: PropTypes) => (
    <Bouton
        id="navigation-menu"
        ariaLabel="navigation menu"
        ariaExpanded={props.isOpened}
        ariaControls={props.ariaControls}
        withStyles={false}
        className={classNames({
            [styles.menuBtn]: true,
            [styles.btnBuger]: true,
            [styles.open]: props.isOpened,
        })}
        onClick={props.handleToggleMenu}
    >
        <span />
        <span />
        <span />
        <span />
    </Bouton>
);

export default HamburgerMenuButton;
