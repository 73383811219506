// eslint-disable-next-line no-extend-native
Array.prototype.sortByProperty = function (propName) {
    return this.sort((a, b) => {
        if (a[propName] < b[propName]) {
            return -1;
        }
        if (a[propName] > b[propName]) {
            return 1;
        }
        return 0;
    });
};

// eslint-disable-next-line no-extend-native
Array.prototype.maxByProperty = function (propName) {
    return this.reduce((prev, cur) => (prev?.[propName] > cur[propName] ? prev : cur), undefined);
};

export {};