import {useEffect, useMemo, useState} from 'react';

const useKeyPress = (targetKey) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    const memoTargetKey = useMemo(() => targetKey, [targetKey]);

    // Add event listeners
    useEffect(() => {
        // If pressed key is our target key then set to true
        const downHandler = ({key}) => {
            if (key === memoTargetKey) {
                setKeyPressed(true);
            }
        };
        // If released key is our target key then set to false
        const upHandler = ({key}) => {
            if (key === memoTargetKey) {
                setKeyPressed(false);
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [memoTargetKey]); // Empty array ensures that effect is only run on mount and unmount

    return keyPressed;
};

export default useKeyPress;
