import React, { FormEventHandler, MouseEventHandler, useRef, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { sendSearchEvent } from "@telequebec/tq-googleanalytics";
import { useDebounce } from "../../hooks/useDebounce";

import { path } from "../../utils/path";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import Bouton, { VARIANT } from "../bouton";

import styles from "./searchBox.module.scss";
import { swrFetcher } from "../../utils/request";
import RECHERCHE from "../../configs/recherche";

import SearchResult from "./searchResult";

type SearchBoxProps = {
    className?: string;
};

const SearchBox = ({ className = "" }: SearchBoxProps) => {
    const router = useRouter();
    const [input, setInput] = useState(router.query["term"] || "");
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [resultats, setResultats] = useState([]);
    const [count, setCount] = useState(0);

    const searchBoxRef = useRef(null);
    const panelRef = useRef(null);

    const showPanel = () => {
        setIsOpen(true);
    };
    const hidePanel = () => {
        setIsOpen(false);
    };

    useOnClickOutside([searchBoxRef, panelRef], () => {
        hidePanel();
    });

    const handleType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value = null } = event.target;
        const valueToString = String(value || "");

        if (valueToString.trim().length >= RECHERCHE.stringLength) {
            showPanel();
        } else {
            hidePanel();
        }

        setInput(valueToString.trimStart());
    };

    const handleInputClick: MouseEventHandler<HTMLInputElement> = (event) => {
        const { value = null } = event.currentTarget;
        const valueToString = String(value || "");

        if (valueToString.trim().length >= RECHERCHE.stringLength) {
            showPanel();
        }
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        try {
            event.preventDefault();

            const inputSearch = String(input || "").trim();
            if (inputSearch.length >= RECHERCHE.stringLength) {
                sendSearchEvent({
                    search: {
                        search_term: inputSearch,
                        results_count: count,
                    },
                });

                router.push(path.fill(path.RECHERCHE, { term: inputSearch }));
            }

            hidePanel();
        } catch (e) {
            // ignore
        }
    };

    const handleResultClick = ({
        resultPath,
        contenu,
    }: {
        resultPath: string;
        contenu: {
            [key: string]: unknown;
        };
    }) => {
        router.push(path.fill(resultPath, contenu));
        hidePanel();
    };

    useDebounce(
        () => {
            const inputSearch = String(input).trim();
            setIsLoading(true);
            if (inputSearch.length >= RECHERCHE.stringLength) {
                const params = {
                    count: "3",
                    offset: "0",
                    term: inputSearch,
                };

                const query = new URLSearchParams(params);
                swrFetcher(`/api/recherche?${query}`).then((data) => {
                    const { items: serverResults, count: serverCount } = data;
                    setCount(serverCount || 0);
                    setResultats(serverResults || []);
                    setIsLoading(false);
                });
            } else {
                setCount(0);
                setResultats([]);
                setIsLoading(false);
            }
        },
        RECHERCHE.timeout,
        [input],
    );

    return (
        <div data-component="SearchBox" className={classNames([styles.me, className])}>
            <form onSubmit={handleSubmit} autoComplete="off">
                <label htmlFor="searchbox" className="sr-only">
                    Barre de recherche
                </label>
                <input
                    alt="recherche"
                    autoComplete="off"
                    data-value={input}
                    id="searchbox"
                    onChange={handleType}
                    onClick={handleInputClick}
                    placeholder="Trouvez la recette parfaite..."
                    ref={searchBoxRef}
                    type="text"
                    value={input}
                />
                <Bouton
                    ariaLabel="recherche"
                    className={styles.loupe}
                    enabled={!!input && input.length >= RECHERCHE.stringLength}
                    onClick={handleSubmit}
                    title={`${RECHERCHE.stringLength} caractères minimum`}
                    variant={VARIANT.NONE}
                >
                    <span className="icon-loupe" />
                </Bouton>
            </form>
            <div
                className={classNames(styles.pannel, { [styles.isOpen]: isOpen })}
                ref={panelRef}
                data-testid="result-panel"
            >
                <SearchResult
                    isLoading={isLoading}
                    onResultClick={handleResultClick}
                    results={resultats}
                />
                {!isLoading && count === 0 && (
                    <Bouton className={styles.buttonResult} enabled={false}>
                        {`Aucun résultat pour "${input}"`}
                    </Bouton>
                )}
                {!isLoading && count > 1 && (
                    <Bouton className={styles.buttonResult} count={count} onClick={handleSubmit}>
                        Tous les résultats
                    </Bouton>
                )}
            </div>
        </div>
    );
};

export default SearchBox;
