import classNames from 'classnames';
import React from 'react';
import useKeyPress from '../../hooks/useKeyPress';
import styles from './grilleDebugger.module.scss';

const GrilleDebugger = () => {
    const isGPress = useKeyPress('g');
    if (!isGPress) {
        return null;
    }

    return (
        <div
            data-component="GrilleDebugger"
            className={classNames('body', styles.grilleDebugger)}
        >
            {'1,2,3,4,5,6,7,8,9,10,11,12'.split(',').map((index) => (
                <div key={`GD_${index}`} />
            ))}
        </div>
    );
};

export default GrilleDebugger;
