import {useCallback, useEffect, useRef} from 'react';

export function useTimeout(func, time) {
    const funcRef = useRef(func);
    const timeRef = useRef();

    const set = useCallback(() => {
        timeRef.current = setTimeout(() => funcRef.current(), time);
    }, [time]);

    const clear = useCallback(() => {
        timeRef.current = clearTimeout(timeRef.current);
    }, []);

    const reset = useCallback(() => {
        clear();
        set();
    }, [clear, set]);

    useEffect(() => {
        funcRef.current = func;
    }, [func]);

    useEffect(() => {
        set();

        return clear();
    }, [time, set, clear]);

    return {reset, clear};
}

// Help with the order of Hooks called into component
export function useTimeoutSimple(callback, delay) {
    const timeoutRef = useRef(null);
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current();

        timeoutRef.current = window.setTimeout(tick, delay);
        return () => window.clearTimeout(timeoutRef.current);
    }, [delay]);
    return timeoutRef;
}
