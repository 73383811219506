import { useState, useEffect } from "react";
import debounce from "debounce";

type WindowDimensions = {
    width: number | null;
    height: number | null;
};

const getWindowDimensions = () => {
    const { outerWidth, outerHeight, innerWidth, innerHeight } = window;

    const width = Math.min(outerWidth, innerWidth);
    const height = Math.min(outerHeight, innerHeight);

    return {
        width,
        height,
    };
};

/**
 * This custom hooks abstracts the usage of window resize
 * @param {Function} callback callback that will run when there's a change in resize
 */
export default function useWindowDimensions(callback: Function = () => {}) {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
        width: null,
        height: null,
    });

    const handleResize = () => {
        const dimensions = getWindowDimensions();
        setWindowDimensions(dimensions);
        if (typeof callback === "function") {
            callback(dimensions);
        }
    };

    const debounceHandleResize = debounce(handleResize, 100, {
        immediate: false,
    });

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", debounceHandleResize);
        return () => window.removeEventListener("resize", debounceHandleResize);
    }, []);

    return windowDimensions;
}
