export const stripToInternalUrl = (url) => {
    if (!url) {
        return null;
    }
    return url.replace(/https?:\/\/cuisinez\.(.*\.)?telequebec.tv/, "");
};

export const isUrlExternal = (url) => {
    if (!url) {
        return false;
    }

    return (url.startsWith('https://') || url.startsWith('http://'));
};

export const getDomainName = (url) => {
    const domain = url && (new URL(url)).hostname.replace('www.', '').split('.')[0];
    return domain;
};
