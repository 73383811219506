import React from 'react';
import classNames from 'classnames';

import styles from './skipNavigation.module.scss';

const SkipNavigation = () => (
    <a
        data-component="SkipNavigation"
        id="skip-navigation-link"
        size="sm"
        href="#main-content"
        className={classNames(styles.me)}
    >
        Passer la Navigation

    </a>
);

export default SkipNavigation;
