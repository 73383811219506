import { useEffect, useState } from "react";
import { swrFetcher } from "@/utils/request";
import { EmissionCompact } from "@/types/emissions";
import { MenuNavigation } from "@/types/init";

const useEmissions = () => {
    const [emissions, setEmissions] = useState<EmissionCompact[]>([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchEmissions = async () => {
            const data: MenuNavigation = await swrFetcher("/api/init/menu", controller);
            if (isMounted) {
                setEmissions(data.emissions);
            }
        };

        fetchEmissions();

        return () => {
            isMounted = false;
            if (controller.signal.aborted) {
                controller.abort();
            }
        };
    }, []);

    return emissions;
};

export default useEmissions;
