import {path} from '../../utils/path';

export const FOOTER_LABELS = {
    LEFT: {
        TITLE: 'Explorer',
        LINKS: [
            {
                title: 'Recettes',
                path: path.RECETTES,
            },
            {
                title: 'Planifier',
                path: path.PLANIFIER,
            },
            {
                title: 'Apprendre',
                path: path.APPRENDRE,
            },
            {
                title: 'Émissions',
                path: path.EMISSIONS,
            },
        ],
    },
    CENTER: {
        TITLE: 'Plus de Cuisinez',
        LINKS: [
            {
                title: 'À propos',
                path: path.APROPOS,
            },
            {
                title: 'Foire aux questions',
                path: 'https://info.telequebec.tv/hc/fr',
            },
            {
                title: 'Nous joindre',
                path: 'https://info.telequebec.tv/hc/fr/requests/new?ticket_form_id=360005578334',
            },
        ],

    },
    RIGHT: {
        SOCIALS: {
            TITLE: 'Devenons amis!',
            LINKS: [
                {
                    title: 'facebook',
                    path: 'https://www.facebook.com/CuisinezTQc/',
                },
                {
                    title: 'tiktok',
                    path: 'https://www.tiktok.com/@cuisineztqc',
                },
                {
                    title: 'instagram',
                    path: 'https://www.instagram.com/cuisineztqc/',
                },
                {
                    title: 'pinterest',
                    path: 'https://www.pinterest.ca/cuisineztqc/',
                },
            ],
        },
        INFOLETTRE: {
            TITLE: 'Recevez notre infolettre',
            BTNTEXT: 'Je m’abonne',
        },
    },
};
