import React, { useContext, useEffect, useState } from "react";
import { LayoutContext, SCREENTYPE } from "@/hooks/useLayout";
import Icon from "../icon";
import classNames from "classnames";
import styles from "./scrollToTop.module.scss";

const ScrollToTop = ({ showScrollToTop }: { showScrollToTop: boolean }) => {
    const { screenType } = useContext(LayoutContext);

    const [hash, setHash] = useState<string>("#root");
    const [ariaLabel, setAriaLabel] = useState<string>("Haut de page");
    const [ariaLabelledBy, setAriaLabelledBy] = useState<string>("scrollTopLabel");

    useEffect(() => {
        if (screenType == SCREENTYPE.MOBILE) {
            setHash("#root");
            setAriaLabel("Haut de page");
            setAriaLabelledBy("");
        } else {
            setHash("#skip-navigation-link");
            setAriaLabel("");
            setAriaLabelledBy("scrollTopLabel");
        }
    }, [screenType]);

    return (
        <a
            data-component="ScrollToTop"
            href={hash}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            className={classNames(styles.me, {
                [styles.showScrollToTop]: showScrollToTop,
            })}
        >
            <Icon icon="fleche-up" />
            <span id="scrollTopLabel">Haut de page</span>
        </a>
    );
};

export default ScrollToTop;
