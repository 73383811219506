export const TYPE_CATEGORY = {
    ARTICLE: 'article',
    RECETTE: 'recette',
};

export const TYPE_CONTENU = {
    ARTICLE: 'article',
    CATEGORIE: 'categorie',
    EMISSION: 'emission',
    EPISODE: 'episode',
    INFOLETTRE: 'infolettre',
    PUBLICATION: 'publication',
    PUBLICITE: 'publicite',
    RECETTE: 'recette',
};

export type TYPE_CONTENU_ENUM =
    | 'article'
    | 'categorie'
    | 'emission'
    | 'episode'
    | 'infolettre'
    | 'publicite'
    | 'recette'
    | 'publication'


export const TYPE_GABARIT = {
    FORMAT_1X1: 'format1x1',
    FORMAT_16X9: 'format16x9',
    FORMAT_2X3: 'format2x3',
    FORMAT_LOGO: 'logo',
    FORMAT_CAROUSEL_DESKTOP: 'carouselDesktop',
};

export const TYPE_AUTEUR = {
    PHOTOGRAPHE: 'Photographe',
    REDACTION: 'La rédaction',
    COMPLICE: 'Complice',
    COMMANDITAIRE: 'Commanditaire',
};

export const LABEL_TAGINTO = {
    ARTICLE: 'Article',
    RECETTE: 'Recette',
    EPISODE: 'Épisode',
    QUIZ: 'Quiz',
};

export const TYPE_TEMPLATE_COLLECTION = {
    TEMPLATE3COLUMNS: 'TEMPLATE3COLUMNS',
};
