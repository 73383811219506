import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { VARIANT, patchVariant, BouttonProps, SHAPE } from "../bouton";

import Icon from "../icon";

import styles from "../bouton/bouton.module.scss";

export type BoutonLinkProps = {
    dataTestid?: string;
    href: string;
    rel?: string;
    target?: string;
} & BouttonProps;

const BoutonLink = React.forwardRef<HTMLAnchorElement, BoutonLinkProps>(
    (
        {
            ariaControls,
            ariaExpanded,
            ariaLabel,
            ariaLabelledBy,
            ariaSelected,
            borderOnly = false,
            children,
            className = "",
            color,
            count,
            dataTestid = "mainBtnLink",
            disabled = false,
            enabled = true,
            href,
            icon,
            iconLeft = true,
            iconRight = false,
            id,
            invert = false,
            onClick = () => {},
            onClickParam,
            rel,
            role,
            shape = SHAPE.DEFAUT,
            size,
            target,
            title,
            to,
            variant: variantProps,
            withStyles = true,
        }: BoutonLinkProps,
        ref,
    ) => {
        const router = useRouter();

        //  Patch temporaire pour le changement progressif
        const variant = variantProps || patchVariant(borderOnly, invert, withStyles);

        const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
            if (enabled) {
                if (onClick) {
                    if (onClickParam) {
                        onClick(onClickParam, e);
                    } else {
                        onClick(e);
                    }
                }

                if (to) {
                    router.push(to);
                }
            }
        };

        if (!href) {
            // If no href, the LINK not working
            return null;
        }

        return (
            (<Link
                href={href}
                aria-controls={ariaControls}
                aria-expanded={ariaExpanded}
                aria-selected={ariaSelected}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledBy}
                className={classNames(styles.meGlobal, {
                    [className]: className,
                    [styles.me]: variant !== VARIANT.NONE && variant !== VARIANT.LINK,
                    [styles.disabled]: !enabled || disabled,
                    [styles[`${color}`]]: typeof styles[`${color}`] !== "undefined",
                    [styles[shape]]: typeof styles[shape] !== "undefined",
                    [styles[`${size}`]]: typeof styles[`${size}`] !== "undefined",
                    [styles[variant]]: typeof styles[variant] !== "undefined",
                })}
                data-component="BoutonLink"
                data-testid={dataTestid}
                id={id}
                onClick={handleClick}
                ref={ref}
                rel={rel}
                role={role}
                target={target}
                title={title}>

                {iconLeft === true && iconRight !== true && icon && (
                    <Icon
                        className={classNames(
                            styles.icon,
                            {
                                [styles[`${icon}`]]: typeof styles[`${icon}`] !== "undefined",
                                [styles.withText]: children !== null,
                                [styles.iconOnly]:
                                    children === null || typeof children === "undefined",
                                [styles.iconLeft]: true,
                            },
                            "icon iconLeft",
                        )}
                        icon={icon}
                    />
                )}
                {children !== null && (
                    <span
                        className={classNames("btnLabel", {
                            [styles.btnLabel]: styles.btnLabel,
                        })}
                    >
                        {children}
                    </span>
                )}
                {iconRight === true && icon && (
                    <Icon
                        className={classNames(
                            styles.icon,
                            {
                                [styles[`${icon}`]]: typeof styles[`${icon}`] !== "undefined",
                                [styles.withText]: children !== null,
                                [styles.iconOnly]:
                                    children === null || typeof children === "undefined",
                                [styles.iconRight]: true,
                            },
                            "icon iconRight",
                        )}
                        icon={icon}
                    />
                )}
                {count && count >= 0 && (
                    <small data-testid="btn-count" className={styles.count}>
                        {`(${count})`}
                    </small>
                )}

            </Link>)
        );
    },
);

BoutonLink.displayName = "BoutonLink";
export default BoutonLink;
