import { useEffect, useState, useContext } from "react";
import { CATEGORIES } from "@/configs/categories";
import "@/utils/extensions";
import { path } from "@/utils/path";
import useEmissions from "./useEmissions";
import { EmissionCompact } from "@/types/emissions";
import { CategoryContext } from "./useCategories";

type ParentNavItem = {
    id?: number;
    url?: string;
    allLabel?: string;
};

export type NavItem = {
    id?: number;
    key: string;
    label?: string;
    url?: string;
    ordre?: number;
    parent?: NavItem;
    allLabel?: string;
    level: number;
    children: NavItem[];
};

const useNavItems = () => {
    const emissions = useEmissions();
    const [navItems, setNavItems] = useState<NavItem>();
    const categories = useContext(CategoryContext);

    const buildCategorieNavItemRecursive = (parentItem: ParentNavItem, root: NavItem) => {
        if (!categories || !Array.isArray(categories)) {
            return;
        }
        const cat = categories.find((c) => c.id === parentItem.id);

        const navItem: NavItem = {
            id: cat?.id,
            key: `CAT_${cat?.id}`,
            label: cat?.nom,
            url: parentItem.url || path.fill(path.CATEGORY, { id: cat?.id, slug: cat?.slug }),
            ordre: cat?.ordre,
            parent: root,
            allLabel: parentItem.allLabel,
            level: (root?.level || 0) + 1,
            children: [],
        };

        navItem.children = categories
            .filter((c) => c.parentId === parentItem.id)
            .map((c) => buildCategorieNavItemRecursive(c, navItem))
            .sortByProperty("ordre");

        return navItem;
    };

    const buildEmissionNavItem = (rootNavItem: NavItem, emissions: EmissionCompact[]) => {
        const emissionRoot: NavItem = {
            id: undefined,
            key: "EMI_root",
            label: "Émissions",
            url: path.EMISSIONS,
            parent: rootNavItem,
            allLabel: "Toutes les émissions",
            level: 1,
            children: [],
        };

        emissionRoot.children = emissions.map((e) => ({
            id: undefined,
            key: `EMI_${e.id}`,
            label: e.nom,
            url: path.fill(path.EMISSION, { id: e.id, slug: e.slug }),
            parent: emissionRoot,
            level: 2,
            children: [],
        }));

        return emissionRoot;
    };

    const buildRootNavItem = () => {
        const root: NavItem = {
            key: "root",
            parent: undefined,
            level: 0,
            children: [],
        };

        const catNavItems = [
            {
                id: CATEGORIES.RECETTES,
                allLabel: "Toutes les recettes",
                url: path.RECETTES,
            },
            {
                id: CATEGORIES.PLANIFIER,
                allLabel: "Tous les articles",
                url: path.PLANIFIER,
            },
            {
                id: CATEGORIES.APPRENDRE,
                allLabel: "Tous les articles",
                url: path.APPRENDRE
            },
        ].map((item) => buildCategorieNavItemRecursive(item, root));

        if (catNavItems.length > 0) {
            root.children.push(...catNavItems as NavItem[]);
        }

        root.children.push(buildEmissionNavItem(root, emissions));

        return root;
    };

    useEffect(() => {
        if (emissions && categories) {
            setNavItems(() => buildRootNavItem());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emissions, categories]);

    return navItems;
};

export default useNavItems;
