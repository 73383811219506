/* eslint-disable react/no-array-index-key */
import React from "react";
import classNames from "classnames";

import styles from "./icon.module.scss";

type IconProps = {
    ariaLabel?: string;
    ariaLabelledBy?: string;
    icon: string;
    className?: string;
};

const Icon = ({ ariaLabel, ariaLabelledBy, icon, className = "" }: IconProps) => (
    <em
        data-component="Icon"
        data-testid={`icon-${icon}`}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        className={classNames(`icon-${icon}`, {
            [className]: className,
            [styles[icon]]: typeof styles[icon] !== "undefined",
        })}
    />
);

export default Icon;
