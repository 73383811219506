export const path = {
    fill: (thePath: string, obj?: { [key: string]: unknown }) =>
        `${thePath
            .split("/")
            .map((part) => (part[0] === ":" ? obj?.[part.slice(1).replace("?", "")] : part))
            .join("/")
            .replace(/\/+$/, "")
            .split("=")
            .map((part) => (part[0] === ":" ? obj?.[part.slice(1).replace("?", "")] : part))
            .join("=")}`,
    ACCUEIL: "/",
    APPRENDRE: "/apprendre",
    APROPOS: "/apropos",
    ARTICLE: "/articles/:id/:slug?",
    ARTICLES: "/articles",
    CATEGORY: "/categories/:id/:slug?",
    CATEGORY_ARTICLE: "/categories-articles/:id/:slug?",
    COMPLICE: "/complices/:id/:slug?",
    COMPLICES: "/complices",
    EMISSION: "/emissions/:id/:slug?",
    EMISSIONS: "/emissions/",
    EPISODEBYID: "/emissions/:id/:slug/episode/:idEpisode",
    EPISODEBYSAISON: "/emissions/:id/:slug/saison/:noSaison?/episode/:noEpisode?",
    LEXIQUE: "/lexique/:id?",
    PLANIFIER: "/planifier",
    PUBLICATIONS: "/publications/:id/:slug?",
    RECETTE: "/recettes/:id/:slug?",
    RECETTES: "/recettes",
    RECHERCHE: "/recherche/?term=:term",
    RECHERCHES: "/recherche",
};

export const pathName = {
    CATEGORY: "/categories/[id]/[slug]",
    EMISSION: "/emissions/[id]/[slug]",
    EPISODEBYSAISON: "/emissions/[id]/[slug]/saison/[noSaison]/episode/[noEpisode]",
    EPISODE: "/emissions/[id]/[slug]/episode/[noEpisode]",
};

export const pathRoutes = [
    {
        path: path.ACCUEIL,
        name: "Accueil",
    },
    {
        path: path.APPRENDRE,
        name: "Apprendre",
    },
    {
        path: path.APROPOS,
        name: "Apropos",
    },
    {
        path: path.CATEGORY_ARTICLE,
        name: "Article",
    },
    {
        path: path.ARTICLE,
        name: "Article",
        parent: {
            path: path.ARTICLES,
            name: "Articles",
        },
    },
    {
        path: path.ARTICLES,
        name: "Articles",
    },
    {
        path: pathName.CATEGORY,
        name: "Catégorie",
        // parent: {
        //     path: path.RECETTES,
        //     name: 'Recettes',
        // },
    },
    {
        path: path.COMPLICE,
        name: "Complice",
    },
    {
        path: path.COMPLICES,
        name: "Complices",
    },
    {
        path: path.EMISSION,
        name: "Émission",
        parent: {
            path: path.EMISSIONS,
            name: "Émissions",
        },
    },
    {
        path: pathName.EMISSION,
        name: "Émission",
    },
    {
        path: path.EMISSIONS,
        name: "Émissions",
    },
    {
        path: pathName.EPISODEBYSAISON,
        name: "Épisode",
        parent: {
            path: path.EMISSIONS,
            name: "Émissions",
        },
    },
    {
        path: pathName.EPISODE,
        name: "Épisode",
        parent: {
            path: path.EMISSIONS,
            name: "Émissions",
        },
    },
    {
        path: path.LEXIQUE,
        name: "Lexique",
    },
    {
        path: path.PLANIFIER,
        name: "Planifier",
    },
    {
        path: path.RECETTE,
        name: "Recette",
        parent: {
            path: path.RECETTES,
            name: "Recettes",
        },
    },
    {
        path: path.RECETTES,
        name: "Recettes",
    },
    {
        path: path.RECHERCHE,
        name: "Recherche",
    },
];

export default path;
