import React, { MouseEventHandler } from "react";
import { FaFacebookF, FaInstagram, FaPinterestP, FaTiktok } from "react-icons/fa";

import styles from "./socialLink.module.scss";

type PropTypes = {
    social?: string;
    link?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const SocialIcon = ({ social }: PropTypes) => {
    switch (social) {
        case "facebook":
            return <FaFacebookF aria-labelledby="facebook-link" />;
        case "tiktok":
            return <FaTiktok aria-labelledby="tiktok-link" />;
        case "instagram":
            return <FaInstagram aria-labelledby="instagram-link" />;
        case "pinterest":
            return <FaPinterestP aria-labelledby="pinterest-link" />;
        default:
            return null;
    }
};

const SocialLink = ({ social, link, onClick }: PropTypes) => (
    <a
        id={`${social}-link`}
        aria-label={social}
        className={styles.me}
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
    >
        <span>
            <SocialIcon social={social} />
        </span>
    </a>
);

export default SocialLink;
