import classNames from "classnames";
import React, { useEffect, memo, createRef, useState } from "react";
import { useRouter } from "next/router";
import styles from "./pub.module.scss";

declare global {
    interface Window {
        madops: any;
    }
}

export enum PUBFORMATS {
    mobileLeaderboard = "mobile-leaderboard",
    leaderboard = "leaderboard",
    leaderboardBillboard = "leaderboard-billboard",
    mediumRectangle = "medium-rectangle",
    mediumRectangleHalfPage = "medium-rectangle-half-page",
}

const getSizes = (format: PUBFORMATS) => {
    switch (format) {
        case "mobile-leaderboard":
            return { sizes: "[[320,50]]", sizeMapping: "" };
        case "leaderboard":
            return { sizes: "[[728,90]]", sizeMapping: "" };
        case "leaderboard-billboard":
            return {
                sizes: "[[320,50],[728,90],[970,250]]",
                sizeMapping:
                    '[{"viewport":{"min_width":0, "min_height":0}, "sizes":[[320,50]]}, {"viewport":{"min_width":768, "min_height":0}, "sizes":[[728,90]]}, {"viewport":{"min_width":1024, "min_height":0}, "sizes":[[970,250]]}]',
            };
        case "medium-rectangle":
            return { sizes: "[[300,250]]", sizeMapping: "" };
        case "medium-rectangle-half-page":
            return { sizes: "[[300,250], [300,600]]", sizeMapping: "" };
        default:
            return { sizes: "[[300,250]]", sizeMapping: "" };
    }
};

type PubProps = {
    className?: string;
    format: PUBFORMATS;
};

const Pub = ({ format }: PubProps) => {
    // const isUserConsent = window.Didomi?.getUserConsentStatusForVendor(75) && window.Didomi?.getUserConsentStatusForVendor("google");
    // if (!window.madops || isUserConsent === false) return null

    const router = useRouter();
    const pubRef = createRef<HTMLDivElement>();
    const [pubOptions, setPubOptions] = useState<string>("");
    
    const buildOptions = () => {
        const { sizes, sizeMapping } = getSizes(format);

        const options = {
            sizes: sizes,
            sizeMapping: sizeMapping,
            dfpId: "21658289790,270051662",
            dfpAdUnitPath: `cuisinez/${format}-${Math.floor(Math.random() * 1000000)}`,
            kv: {
                projet: "cuisinez",
                emission: "",
                section: "",
            },
        };

        const isEpisode = router.asPath.split("/").includes("episode");

        const section = isEpisode ? "episode" : router.asPath.split("/")[1] || "accueil";
        options.kv.section = section.replace(/s$/, "");

        const isEmission = options.kv.section == "emission" || isEpisode;
        options.kv.emission = isEmission ? router.asPath.split("/")[3] || "" : "";

        return JSON.stringify(options);
    };

    useEffect(() => {
        if (!pubRef.current?.dataset["options"]?.length) {
            setPubOptions(buildOptions());
        }
    }, []);

    useEffect(() => {
        const setOptionsOnRouteChange = () => {
            if (!pubRef.current) {
                setPubOptions(buildOptions());
            }
        };

        router.events.on("routeChangeComplete", setOptionsOnRouteChange);
        return () => {
            router.events.off("routeChangeComplete", setOptionsOnRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        if (pubRef.current?.dataset["options"]?.length) {
            window.madops?.addDynamicAd(pubRef.current);
        }
    }, [pubRef]);

    return (
        <div className={classNames("pub", styles.pub, styles[format], format)}>
            <div ref={pubRef} data-m32-dynamic-ad data-options={pubOptions}></div>
        </div>
    );
};

export default memo(Pub);
