import classNames from "classnames";
import React, { useEffect, useState } from "react";
import useNavItems, { NavItem } from "../../hooks/useNavItems";
import BarTeleQuebec from "../../ui/barTeleQuebec/BarTeleQuebec";
import Bouton, { VARIANT } from "../../ui/bouton/index";
import BoutonLink from "../../ui/boutonLink";

import styles from "./navMobile.module.scss";

type PropTypes = {
    linkClicked?: Function;
};

const NavMobile = (props: PropTypes) => {
    const navItems = useNavItems();
    const [currentNavItem, setCurrentNavItem] = useState(navItems);
    const [hideAnimation, setHideAnimation] = useState(false);

    useEffect(() => {
        if (navItems) {
            // Add a navItem for Accueil
            navItems.children.unshift({
                id: -1,
                key: "accueil",
                label: "Accueil",
                url: "/",
                parent: undefined,
                children: [],
                level: 1,
            });
            setCurrentNavItem(navItems);
        }
    }, [navItems]);

    const navItemClicked = (navItem: NavItem | undefined) => {
        if (navItem?.children?.length) {
            setHideAnimation(true);
            setTimeout(() => {
                setCurrentNavItem(navItem);
                setHideAnimation(false);
            }, 200);
        }
    };

    if (!currentNavItem) {
        return null;
    }

    return (
        <nav id="nav-mobile-panel" data-component="NavMobile" className={styles.NavMobile}>
            <div className={styles.navPagesContainer}>
                <div
                    className={classNames(styles.navPageContainer, {
                        [styles.hideAnimation]: hideAnimation,
                    })}
                >
                    <div className={styles.navPage}>
                        {currentNavItem.parent && (
                            <div className={styles.backButton}>
                                <Bouton
                                    onClick={() => navItemClicked(currentNavItem.parent)}
                                    icon="fleche"
                                    variant={VARIANT.DARK}
                                >
                                    Retour
                                </Bouton>
                            </div>
                        )}

                        {currentNavItem.children.map((childItem) => (
                            <div className={styles.navItem} key={childItem.key}>
                                {childItem.children?.length ? (
                                    <Bouton
                                        withStyles={false}
                                        className={styles.navItemButton}
                                        icon="fleche-right"
                                        iconRight
                                        onClick={() => navItemClicked(childItem)}
                                    >
                                        {childItem.label}
                                    </Bouton>
                                ) : (
                                    <BoutonLink
                                        withStyles={false}
                                        className={styles.navItemButton}
                                        href={childItem.url || ""}
                                        onClick={() => props.linkClicked && props.linkClicked()}
                                    >
                                        {childItem.label}
                                    </BoutonLink>
                                )}
                            </div>
                        ))}

                        {currentNavItem.allLabel && (
                            <div className={styles.allButton}>
                                <BoutonLink
                                    href={currentNavItem.url || ""}
                                    icon="fleche-right"
                                    iconRight
                                    onClick={() => props.linkClicked && props.linkClicked()}
                                    variant={VARIANT.INVERT}
                                >
                                    {currentNavItem.allLabel}
                                </BoutonLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <BarTeleQuebec />
        </nav>
    );
};

export default NavMobile;
