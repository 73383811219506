import React from "react";
import BarTeleQuebec from "@/ui/barTeleQuebec/BarTeleQuebec";
import { PUBFORMATS } from "@/ui/pub";
import NavDesktop from "../nav/navDesktop";
import styles from "./headerDesktop.module.scss";

import Pub from "@/ui/pub";

const HeaderDesktop = () => {
    return (
        <>
            <Pub format={PUBFORMATS.leaderboard} />
            <div className={styles.header}>
                <BarTeleQuebec />
                <NavDesktop />
            </div>
        </>
    );
};

export default HeaderDesktop;
