import React from "react";
import Link from "next/link";
import classNames from "classnames";
import { sendSocialEvent } from "@telequebec/tq-googleanalytics";
import URLS from "../../configs/urls";
import { isUrlExternal } from "../../utils/urls";
import { getSlugByTitle } from "../../utils/string";

import { VARIANT } from "../../ui/bouton";
import BoutonLink from "../../ui/boutonLink";
import SocialLink from "../../ui/socialLink/SocialLink";

import { FOOTER_LABELS } from "./footer.constant";
import styles from "./footer.module.scss";

const Footer = () => {
    const sendSocialMediaDataLayer = (media: string) => {
        sendSocialEvent({
            social: media,
        });
    };

    const renderLink = (title: string, path: string) => {
        if (!path) {
            return null;
        }

        if (isUrlExternal(path)) {
            return (
                <a href={path} target="_blank" rel="noreferrer">
                    {title}
                </a>
            );
        }

        return <Link href={path}>{title}</Link>;
    };

    return (
        <footer data-component="Footer" className={styles.me}>
            <div className={classNames(styles.body, "body")}>
                <div>
                    <h4>{FOOTER_LABELS.LEFT.TITLE}</h4>
                    <ul>
                        {FOOTER_LABELS.LEFT.LINKS.map((link) => (
                            <li key={`${getSlugByTitle(link.title)}-item`}>
                                {renderLink(link.title, link.path)}
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h4>{FOOTER_LABELS.CENTER.TITLE}</h4>
                    <ul>
                        {FOOTER_LABELS.CENTER.LINKS.map((link) => (
                            <li key={`${getSlugByTitle(link.title)}-item`}>
                                {renderLink(link.title, link.path)}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.last}>
                    <div className={styles.logoDisplay} />

                    <h5>{FOOTER_LABELS.RIGHT.SOCIALS.TITLE}</h5>
                    <ul className={styles.itemsLink}>
                        {FOOTER_LABELS.RIGHT.SOCIALS.LINKS.map((link) => (
                            <li
                                key={`${getSlugByTitle(link.title)}-item`}
                                className={styles.itemList}
                            >
                                <SocialLink
                                    social={link.title}
                                    link={link.path}
                                    onClick={() => sendSocialMediaDataLayer(link.title)}
                                />
                            </li>
                        ))}
                    </ul>

                    <h5>{FOOTER_LABELS.RIGHT.INFOLETTRE.TITLE}</h5>
                    <BoutonLink
                        href={URLS.infolettre}
                        rel="noreferrer"
                        target="_blank"
                        variant={VARIANT.PRIMARY}
                    >
                        {FOOTER_LABELS.RIGHT.INFOLETTRE.BTNTEXT}
                    </BoutonLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
