/* eslint-disable react/no-danger */
import React from "react";
import sanitizeHtml from "sanitize-html";

const defaultOptions = {
    allowedTags: ["b", "i", "em", "strong", "a", "br"],
    allowedAttributes: {
        a: ["href"],
    },
};

const sanitize = (dirty: string, options: sanitizeHtml.IOptions) => {
    const newOptions = options ? { ...defaultOptions, ...options } : defaultOptions;

    return {
        __html: sanitizeHtml(dirty, newOptions),
    };
};

type PropTypes = {
    className?: string;
    dataTestid?: string;
    html: string;
    options?: any;
    ref?: any;
    style?: Object;
    tag?: Extract<
        keyof JSX.IntrinsicElements,
        "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "div"
    >;
};

const SanitizeHTML = ({
    className = "",
    dataTestid,
    html,
    options,
    ref,
    style,
    tag = "div",
}: PropTypes) => {
    const HtmlTag = tag;

    return (
        <HtmlTag
            className={className}
            data-testid={dataTestid}
            dangerouslySetInnerHTML={sanitize(html, options)}
            ref={ref}
            style={style}
        />
    );
};

export default SanitizeHTML;
