import React, { useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import Icon from "../icon";

import styles from "./bouton.module.scss";

export const VARIANT = {
    BORDER: "border",
    DANGER: "danger",
    DARK: "dark",
    INVERT: "invert",
    LIGHT: "light",
    LINK: "link",
    NONE: "none",
    PRIMARY: "primary",
};

export const SHAPE = {
    CARRE: "carre",
    DEFAUT: "defaut",
    ROND: "rond",
};

export const SIZE = {
    XS: "xs",
    SM: "sm",
    DEFAUT: "defaut",
    LG: "lg",
    XL: "xl",
};

export const patchVariant = (borderOnly: boolean, invert: boolean, withStyles: boolean) => {
    if (borderOnly) {
        return VARIANT.BORDER;
    }

    if (invert) {
        return VARIANT.INVERT;
    }

    if (!withStyles) {
        return VARIANT.LINK;
    }

    return VARIANT.PRIMARY;
};

export type BouttonProps = {
    ariaControls?: string;
    ariaExpanded?: boolean;
    ariaLabel?: string;
    ariaLabelledBy?: string;
    ariaSelected?: boolean;
    borderOnly?: boolean;
    children?: React.ReactNode;
    className?: string;
    color?: string;
    count?: number;
    dataTestid?: string;
    disabled?: boolean;
    enabled?: boolean;
    icon?: string;
    iconLeft?: boolean;
    iconRight?: boolean;
    id?: string;
    invert?: boolean;
    name?: string;
    onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
    onClick?: (arg1: any, arg2?: any) => void;
    onClickParam?: any;
    onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseOut?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    role?: string;
    shape?: string;
    size?: string;
    title?: string;
    to?: string;
    variant?: string;
    withStyles?: boolean;
};

const Bouton = React.forwardRef<HTMLButtonElement, BouttonProps>(
    (
        {
            ariaControls,
            ariaExpanded,
            ariaLabel,
            ariaLabelledBy,
            ariaSelected,
            borderOnly = false,
            children = null,
            className = "",
            color,
            count,
            dataTestid,
            disabled = false,
            enabled = true,
            icon,
            iconLeft = true,
            iconRight = false,
            id,
            invert = false,
            name,
            onBlur = () => {},
            onClick = () => {},
            onClickParam,
            onMouseEnter = () => {},
            onMouseOut = () => {},
            role,
            shape = SHAPE.DEFAUT,
            size,
            title,
            to,
            variant: variantProps,
            withStyles = true,
        }: BouttonProps,
        ref: React.Ref<HTMLButtonElement>,
    ) => {
        const router = useRouter();
        const [isLoading, setIsLoading] = useState(false);
        //  Patch temporaire pour le changement progressif
        const variant = variantProps || patchVariant(borderOnly, invert, withStyles);

        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (enabled) {
                if (onClick) {
                    setIsLoading(true);
                    if (onClickParam) {
                        onClick(onClickParam, e);
                    } else {
                        onClick(e);
                    }
                    setIsLoading(false);
                }

                if (to) {
                    router.push(to);
                }
            }
        };

        return (
            <button
                aria-controls={ariaControls}
                aria-expanded={ariaExpanded}
                aria-selected={ariaSelected}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledBy}
                className={classNames(styles.meGlobal, {
                    [className]: className,
                    [styles.me]: variant !== VARIANT.NONE && variant !== VARIANT.LINK,
                    [styles.disabled]: !enabled || disabled,
                    [styles[`${color}`]]: typeof styles[`${color}`] !== "undefined",
                    [styles[shape]]: typeof styles[shape] !== "undefined",
                    [styles[`${size}`]]: typeof styles[`${size}`] !== "undefined",
                    [styles[variant]]: typeof styles[variant] !== "undefined",
                })}
                data-component="Bouton"
                data-testid={dataTestid}
                disabled={!enabled}
                id={id}
                name={name || title}
                onBlur={onBlur}
                onClick={handleClick}
                onMouseEnter={onMouseEnter}
                onMouseOut={onMouseOut}
                ref={ref}
                role={role}
                title={title}
                type="button"
            >
                {isLoading && (
                    <span
                        data-testid="btn-spiner"
                        className={classNames([
                            styles.icon,
                            {
                                [styles.hasLabel]: children !== null,
                            },
                        ])}
                    >
                        ...
                    </span>
                )}
                {iconLeft === true && iconRight !== true && icon && (
                    <Icon
                        className={classNames(
                            styles.icon,
                            {
                                [styles[`${icon}`]]: typeof styles[`${icon}`] !== "undefined",
                                [styles.withText]: children !== null,
                                [styles.iconOnly]:
                                    children === null || typeof children === "undefined",
                                [styles.iconLeft]: true,
                            },
                            "icon",
                        )}
                        icon={icon}
                    />
                )}
                {children !== null && (
                    <span
                        className={classNames("btnLabel", {
                            [styles.btnLabel]: styles.btnLabel,
                        })}
                    >
                        {children}
                    </span>
                )}
                {iconRight === true && icon && (
                    <Icon
                        className={classNames(
                            styles.icon,
                            {
                                [styles[`${icon}`]]: typeof styles[`${icon}`] !== "undefined",
                                [styles.withText]: children !== null,
                                [styles.iconOnly]:
                                    children === null || typeof children === "undefined",
                                [styles.iconRight]: true,
                            },
                            "icon",
                        )}
                        icon={icon}
                    />
                )}
                {typeof count === "number" && count >= 0 && (
                    <small data-testid="btn-count" className={styles.count}>
                        {`(${count})`}
                    </small>
                )}
            </button>
        );
    },
);

export default Bouton;
