import classNames from "classnames";
import React from "react";

import { TYPE_CONTENU, TYPE_GABARIT } from "../../../utils/enums";
import { path } from "../../../utils/path";
import { Recette } from "@/types/recette";
import { Publication } from "@/types/publication";

import Bouton, { VARIANT } from "../../bouton";
import Image from "../../image";
import Loading from "../../loading";
import SanitizeHmtl from "../../sanitizeHTML";

import styles from "./searchResult.module.scss";

type PropTypes = {
    className?: string;
    isLoading: boolean;
    onResultClick: Function;
    results: Array<Recette | Publication>;
};

const SearchResult = ({ className = "", isLoading = true, onResultClick, results }: PropTypes) => {
    if (isLoading) {
        return (
            <div
                data-component="SearchResult"
                className={classNames(styles.me, {
                    [className]: className,
                    [styles.loading]: isLoading,
                })}
                data-testid="searchResultNoContent"
            >
                <Loading />
            </div>
        );
    }

    const handleResultClick = (params: { resultPath: string; contenu: Recette | Publication }) => {
        if (onResultClick) {
            onResultClick(params);
        }
    };

    return (
        <div
            data-component="SearchResult"
            className={classNames(styles.me, {
                [className]: className,
            })}
            data-testid="searchResult"
        >
            <ul>
                {results &&
                    results.map(({ id, images, slug, titre, type }, index) => {
                        let pathType;
                        switch (type) {
                            case TYPE_CONTENU.RECETTE:
                                pathType = path.RECETTE;
                                break;

                            case TYPE_CONTENU.PUBLICATION:
                                pathType = path.ARTICLE;
                                break;

                            default:
                                pathType = path.ARTICLE;
                                break;
                        }

                        return (
                            <li key={`results-${id}`}>
                                <Bouton
                                    onClick={handleResultClick}
                                    onClickParam={{
                                        resultPath: pathType,
                                        contenu: { id, type, slug },
                                    }}
                                    variant={VARIANT.NONE}
                                    dataTestid={`result-${index}`}
                                >
                                    <Image
                                        className={styles.thumbnail}
                                        gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                                        images={images}
                                    />
                                    <div>
                                        <span className={styles.description}>{type}</span>
                                        <SanitizeHmtl
                                            className={styles.nom}
                                            html={titre}
                                            tag="span"
                                            dataTestid={`result-${index}-title`}
                                        />
                                    </div>
                                </Bouton>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default SearchResult;
