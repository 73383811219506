import classNames from 'classnames';
import React, {useRef, useState} from 'react';

import useNavItems from '../../hooks/useNavItems';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Bouton, {VARIANT} from '../../ui/bouton';
import BoutonLink from '../../ui/boutonLink';
import styles from './navDesktop.module.scss';
import Image from 'next/image';

const NavDesktop = () => {
    const rootNavItem = useNavItems();

    const [selectedNavItems, setSelectedNavItems] = useState([]);
    const [selectedNavItemLevel1, setSelectedNavItemLevel1] = useState();
    const [selectedNavItemLevel2, setSelectedNavItemLevel2] = useState();
    const [selectedNavItemLevel3AndMore, setSelectedNavItemLevel3AndMore] = useState();

    const navHeaderRef = useRef();
    const navPanelRef = useRef();

    const updateSelectedNavItem = (navItem) => {
        if (navItem?.children?.length) {
            // If, item already in the list, remove it by using its parent as selected item
            const selectedItems = selectedNavItems.includes(navItem)
                ? [navItem.parent]
                : [navItem];

            // select all its ancestors too
            let curItem = navItem;
            while (curItem.parent) {
                selectedItems.unshift(curItem.parent);
                curItem = curItem.parent;
            }

            setSelectedNavItems(selectedItems);
            setSelectedNavItemLevel1(selectedItems.find((itm) => itm.level === 1));
            setSelectedNavItemLevel2(selectedItems.find((itm) => itm.level === 2));
            setSelectedNavItemLevel3AndMore(selectedItems.filter((itm) => itm.level >= 3).maxByProperty('level'));
        } else {
            setSelectedNavItems([]);
            setSelectedNavItemLevel1();
            setSelectedNavItemLevel2();
            setSelectedNavItemLevel3AndMore();
        }
    };

    const closeMenu = () => {
        updateSelectedNavItem(undefined);
    };

    useOnClickOutside([navPanelRef, navHeaderRef], () => closeMenu());

    if (!rootNavItem) {
        return null;
    }

    return (
        <nav>
            <div ref={navHeaderRef} className={styles.header}>
                <div className={styles.headerContent}>
                    <div className={styles.logoLinkButton}>
                        <BoutonLink
                            arialabel="accueil"
                            className={styles.logoLinkButtonLink}
                            href="/"
                            onClick={() => closeMenu()}
                            withStyles={false}
                        >
                            <Image
                                alt="Cuisinez"
                                src="/images/logo/logo_cuisinez_horizontal.svg"
                                width="144"
                                height="39"
                            />
                        </BoutonLink>
                    </div>

                    <div className={styles.navItemsLevel1Container}>
                        {rootNavItem.children.map((navItem) => {
                            if (navItem?.label) {
                                return (
                                    <Bouton
                                        key={navItem.key}
                                        className={classNames(styles.navButton, {[styles.selected]: selectedNavItems.includes(navItem)})}
                                        variant={VARIANT.NONE}
                                        onClick={() => updateSelectedNavItem(navItem)}
                                    >
                                        {navItem.label}
                                    </Bouton>
                                )
                            }         
                        }
                        )}
                    </div>
                </div>
            </div>

            {selectedNavItemLevel1?.children?.length > 0 && (
                <div ref={navPanelRef} className={styles.navPanel}>
                    <div className={styles.navItemsLevel2Container}>
                        {selectedNavItemLevel1?.children.map((navItem) => navItem.children?.length ? (
                            <Bouton
                                className={classNames(styles.navButton, {
                                    [styles.selected]: selectedNavItems.includes(navItem),
                                }, styles.level1Children)}
                                icon={'fleche-right'}
                                iconRight
                                key={navItem.key}
                                onClick={() => updateSelectedNavItem(navItem)}
                                variant={VARIANT.NONE}
                            >
                                {navItem.label}
                            </Bouton>
                        ) : (
                            <BoutonLink
                                className={classNames(styles.navButton, {
                                    [styles.selected]: selectedNavItems.includes(navItem),
                                }, styles.level1Children)}
                                href={navItem.url}
                                key={navItem.key}
                                onClick={() => closeMenu()}
                                variant={VARIANT.NONE}
                            >
                                {navItem.label}
                            </BoutonLink>
                        ))}
                        {selectedNavItemLevel1?.allLabel && (
                            <div className={styles.allButton}>
                                <BoutonLink
                                    href={selectedNavItemLevel1.url}
                                    icon="fleche-right"
                                    iconRight
                                    onClick={() => closeMenu()}
                                    variant={VARIANT.INVERT}
                                >
                                    {selectedNavItemLevel1.allLabel}
                                </BoutonLink>
                            </div>
                        )}
                    </div>

                    {selectedNavItemLevel2?.children?.length > 0
                    && (
                        <div className={styles.navItemsLevel3Container}>
                            {(selectedNavItemLevel3AndMore?.children || selectedNavItemLevel2?.children || [])
                                .map((navItem) => navItem.children?.length ? (
                                    <Bouton
                                        className={classNames(styles.navButton, {
                                            [styles.selected]: selectedNavItems.includes(navItem),
                                        }, styles.level2Children)}
                                        icon={'fleche-right'}
                                        iconRight
                                        key={navItem.key}
                                        onClick={() => updateSelectedNavItem(navItem)}
                                        variant={VARIANT.NONE}
                                    >
                                        {navItem.label}
                                    </Bouton>
                                ) : (
                                    <BoutonLink
                                        className={classNames(styles.navButton, {
                                            [styles.selected]: selectedNavItems.includes(navItem),
                                        }, styles.level2Children)}
                                        href={navItem.url}
                                        key={navItem.key}
                                        onClick={() => closeMenu()}
                                        variant={VARIANT.NONE}
                                    >
                                        {navItem.label}
                                    </BoutonLink>
                                ))}
                        </div>
                    ) }
                </div>
            )}
        </nav>
    );
};

export default NavDesktop;
