import {default as lodashIsEqual} from 'lodash.isequal';
import sanitizeHtml from 'sanitize-html';
import slug from 'slug'

export const getSlugByTitle = (title) => {
    return slug(title);
};

export const capitalizeString = (word) => {
    if (!word || typeof word !== 'string') {
        return '';
    }

    return word.charAt(0).toUpperCase() + word.slice(1, word.length);
};

// Changer loaddash pour autre car lourd
// https://gomakethings.com/check-if-two-arrays-or-objects-are-equal-with-javascript/
export const isEqual = (value, other) => lodashIsEqual(value, other);

export const removeHtmlTags = (htmlText) => {
    if (!htmlText) {
        return '';
    }

    return htmlText.replace(/(<([^>]+)>)/ig, '');
};

export const removeTagsAndSanitizeHtml = (htmlText) => sanitizeHtml(htmlText, {
    allowedTags: [],
});

export const removeNewlines = (htmlText) => {
    if (!htmlText) {
        return '';
    }

    return htmlText
        .replace(/\t/g, ' ') // Replace Tabulation
        .replace(/(\r\n|\n|\r)/g, ' ') // Replace new Line
        .replace(/\s\s+/g, ' ') // Replace Multiple place
        .trim();
};

