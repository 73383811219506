import React, { useContext, useEffect, useState } from "react";

import { LayoutContext, SCREENTYPE } from "../../hooks/useLayout";
import SearchBox from "../../ui/searchBox/searchBox";

import styles from "./header.module.scss";
import HeaderDesktop from "./headerDesktop";
import HeaderMobile from "./headerMobile";
import { Viewport } from "@/types/enum";

type HeaderProps = {
    viewport?: Viewport;
};

const Header = ({ viewport }: HeaderProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);
    
    useEffect(() => {
        setIsMobile(viewport == SCREENTYPE.MOBILE || screenType == SCREENTYPE.MOBILE);
    }, [screenType]);

    return (
        <>
            {isMobile ? <HeaderMobile /> : <HeaderDesktop />}

            <div className={styles.searchContainer}>
                <SearchBox />
            </div>
        </>
    );
};

export default Header;
