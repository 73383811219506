import { useState, useEffect } from "react";
import useWindowDimensions from "./useWindowDimensions";

const useScrollToTop = () => {
    const { height } = useWindowDimensions();
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const pageHeightMultiplier = 2;

    useEffect(() => {
        const toggleScrollToTop = () => {
            if (document.documentElement.scrollTop > height * pageHeightMultiplier) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener("scroll", toggleScrollToTop);

        return () => {
            window.removeEventListener("scroll", toggleScrollToTop);
        };
    }, [height]);

    return showScrollToTop;
};

export default useScrollToTop;
