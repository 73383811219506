import React from "react";

import Footer from "./footer";

import GrilleDebugger from "../ui/grilleDebugger/grilleDebugger";
import ScrollToTop from "../ui/scrollToTop";

import useScrollToTop from "../hooks/useScrollToTop";
import SkipNavigation from "../ui/skipNavigation";
import Header from "./header/header";
import { Viewport } from "@/types/enum";

type PropTypes = {
    children: React.ReactNode;
    mainBody?: boolean;
    viewport?: Viewport;
};

const Layout = ({ children, mainBody = true, viewport }: PropTypes) => {
    const showScrollToTop = useScrollToTop();

    return (
        <>
            <SkipNavigation />
            <Header viewport={viewport} />
            <main id="root" className={mainBody ? "body" : ""}>
                {children}
            </main>
            <ScrollToTop showScrollToTop={showScrollToTop} />
            <Footer />
            {process.env.NODE_ENV !== "production" && <GrilleDebugger />}
        </>
    );
};

export default Layout;
